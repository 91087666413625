import { useLayoutEffect, useContext, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import { useEditor } from 'providers/generalContext';

// import ContextWelcome from 'pages/ContextWelcome';

import { ScrollToTop } from 'helpers/scrollToTop';
import { UserContext } from 'contexts';
import SplashScreen from 'components/organisms/SplashScreen';

const ProjectsCover = lazy(() => import('./pages/ProjectsCover'));
const Achado = lazy(() => import('./pages/Achado'));
const AchadoListing = lazy(() => import('./pages/AchadoListing'));
const NotFound = lazy(() => import('./pages/NotFound'));
const ProjectsListing = lazy(() => import('./pages/ProjectsListing'));
const Journalism = lazy(() => import('./pages/Journalism'));
const Home = lazy(() => import('./pages/Home'));
const IndividualNews = lazy(() => import('./pages/IndividualNews'));
const Biography = lazy(() => import('./pages/Biography'));
const Projects = lazy(() => import('./pages/Projects'));
const Education = lazy(() => import('./pages/Education'));
const CalendarPage = lazy(() => import('./pages/CalendarPage'));
const Institutional = lazy(() => import('./pages/Institutional'));
const MapaLeis = lazy(() => import('./pages/MapaLeis'));
const Lupeiros = lazy(() => import('./pages/Lupeiros'));
const InstitutionalArticle = lazy(() => import('./pages/InstitutionalArticle'));
const AdvancedSearch = lazy(() => import('./pages/AdvancedSearch'));
const EducationArticle = lazy(() => import('./pages/EducationArticle'));
const CallToAction = lazy(() => import('./pages/CallToAction'));
const Context = lazy(() => import('./pages/Context'));
const ContextSignUp = lazy(() => import('./pages/ContextSignUp'));
const ContextConfig = lazy(() => import('./pages/ContextConfig'));
const NewsListing = lazy(() => import('./pages/NewsListing'));
const ContextPayment = lazy(() => import('./pages/ContextPayment'));
const EducationArticlesListing = lazy(() => import('./pages/EducationArticlesListing'));
const ContextPlans = lazy(() => import('./pages/ContextPlans'));
const Login = lazy(() => import('./pages/Login'));
const Project = lazy(() => import('./pages/Project'));
const ArticlesListing = lazy(() => import('./pages/ArticlesListing'));
const AcademicArticle = lazy(() => import('./pages/AcademicArticle'));
const TheTrustProject = lazy(() => import('containers/TheTrustProject'));
const Footer = lazy(() => import('components/organisms/Footer'));

function App() {
    const { setIsStoryblokEditor } = useEditor();
    const { user } = useContext(UserContext);

    const preview = window.location.search.includes('_storyblok');

    useLayoutEffect(() => {
        if (preview) {
            setIsStoryblokEditor(true);
        }
        //eslint-disable-next-line
    }, [preview]);

    return (
        <Router>
            <Suspense fallback={<SplashScreen />}>
                <ScrollToTop />
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/nao-encontrado" element={<NotFound />} />
                    <Route exact path="/jornalismo" element={<Journalism />} />
                    <Route exact path="/jornalismo/categoria/:slug" element={<NewsListing />} />
                    <Route exact path="/jornalismo/:slug" element={<IndividualNews />} />
                    <Route exact path="/jornalismo/:year/:month/:day/:slug" element={<IndividualNews />} />
                    <Route exact path="/colaboradores/:slug" element={<Biography />} />
                    <Route exact path="/portfolio" element={<Projects />} />
                    <Route exact path="/portfolio/:slug" element={<ProjectsCover />} />
                    <Route exact path="/portfolio/conteudos/:slug" element={<Project />} />
                    <Route exact path="/portfolio/conteudos" element={<ProjectsListing />} />
                    <Route exact path="/educacao/lupeiros/:slug" element={<Lupeiros />} />
                    <Route exact path="/educacao" element={<Education />} />
                    <Route exact path="/educacao/eventos" element={<CalendarPage />} />
                    <Route exact path="/educacao/categoria/:slug" element={<EducationArticlesListing />} />
                    <Route exact path="/educacao/:slug" element={<EducationArticle />} />
                    <Route exact path="/educacao/:year/:month/:day/:slug" element={<EducationArticle />} />
                    <Route exact path="/educacao/achado" element={<Achado />} />
                    <Route exact path="/educacao/achado/:slug" element={<AcademicArticle />} />
                    <Route exact path="/educacao/achado/categoria/:slug" element={<AchadoListing />} />
                    <Route exact path="/institucional" element={<Institutional />} />
                    <Route exact path="/lupa-mapa-leis-desinformacao" element={<MapaLeis />} />
                    <Route exact path="/institucional/nossas-newsletters" element={<CallToAction />} />
                    <Route exact path="/institucional/categoria/:slug" element={<ArticlesListing />} />
                    <Route exact path="/institucional/:slug" element={<InstitutionalArticle />} />
                    <Route exact path="/institucional/:year/:month/:day/:slug" element={<InstitutionalArticle />} />
                    <Route exact path="/busca/" element={<AdvancedSearch />} />
                    <Route exact path="/busca/:filter" element={<AdvancedSearch />} />
                    <Route exact path="/contexto" element={<Context />} />
                    <Route exact path="/contexto-in" element={<Context login />} />
                    <Route exact path="/contexto/cadastro" element={<ContextSignUp />} />
                    <Route exact path="/contexto/pagamento" element={<ContextPayment />} />
                    <Route exact path="/contexto/planos" element={<ContextPlans />} />
                    {/* <Route exact path="/contexto/boas-vindas" element={<ContextWelcome />} /> */}
                    <Route
                        exact
                        path="/contexto/configuracoes"
                        element={!user && !preview ? <Navigate to="/contexto" /> : <ContextConfig />}
                    />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/global/footer" element={<Footer />} />
                    <Route exact path="/global/the-trust-project" element={<TheTrustProject />} />
                    <Route exact path="/logout" element={<Login />} />
                    <Route path="*" element={<Navigate to="/nao-encontrado" />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default App;
